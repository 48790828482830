import Vue from "vue";

/*TITLE*/
document.title = "Terrazas de Rolando | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Terrazas de Rolando";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Terrazas de Rolando";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-linkfloor-feudal-natural-copia--20230124040117.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "master-bath-linkfloor-feudal-natural-vela-natural-copia--20230124040151.jpg";
Vue.prototype.$image_bath2 = "auxiliar-bath-linkfloor-feudal-natural-vela-natural-copia--20230124040107.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "zona-juegos_noche--20230124040132.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-linkfloor-feudal-natural-copia--20230124040117.jpg",
  },
  {
    src: "salon-linkfloor-feudal-grey-copia--20230124040132.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "master-bath-linkfloor-feudal-natural-vela-natural-copia--20230124040151.jpg",
  },
  {
    src: "master-bath-linkfloor-feudal-grey-vela-natural-copia--20230124040159.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "auxiliar-bath-linkfloor-feudal-natural-vela-natural-copia--20230124040107.jpg",
  },
  {
    src: "auxiliar-bath-linkfloor-feudal-grey-vela-natural-copia--20230124040114.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "zona-juegos_noche--20230124040132.jpg",
  },
  {
    src: "zona-juegos--20230124040154.jpg",
  },
  {
    src: "plaza-tarde--20230124040141.jpg",
  },
  {
    src: "plaza--20230124040149.jpg",
  },
  {
    src: "plaza_2--20230124040156.jpg",
  },
  {
    src: "plaza_superior--20230124040103.jpg",
  },
  {
    src: "plaza-acceso--20230124040112.jpg",
  },
  {
    src: "piscina_2_dia--20230124040137.jpg",
  },
  {
    src: "piscina_2_noche--20230124040144.jpg",
  },
  {
    src: "piscina_2_tarde--20230124040154.jpg",
  },
  {
    src: "piscina_dia--20230124040109.jpg",
  },
  {
    src: "piscina_noche--20230124040119.jpg",
  },
  {
    src: "piscina_tarde--20230124040125.jpg",
  },
  {
    src: "pasarelas-alto--20230124040150.jpg",
  },
  {
    src: "pasarelas-bajo--20230124040156.jpg",
  },
  {
    src: "pasarelas-traseras--20230124040103.jpg",
  },
  {
    src: "20-azotea--20230124040116.jpg",
  },
  {
    src: "lateral--20230124040131.jpg",
  },
  {
    src: "general-1--20230124040154.jpg",
  },
  {
    src: "general-2--20230124040106.jpg",
  },
  {
    src: "general-3--20230124040113.jpg",
  },
  {
    src: "general-4-noche--20230124040124.jpg",
  },
  {
    src: "general-4-tarde--20230124040129.jpg",
  },
  {
    src: "general-4--20230124040137.jpg",
  },
  {
    src: "general-esquina-superior--20230124040145.jpg",
  },
  {
    src: "general-terrazas--20230124040150.jpg",
  },
  {
    src: "fachada--20230124040158.jpg",
  },
  {
    src: "exterior_dia--20230124040115.jpg",
  },
  {
    src: "exterior_noche--20230124040119.jpg",
  },
  {
    src: "exterior_tarde--20230124040124.jpg",
  },
  {
    src: "escaleras_entrada--20230124040133.jpg",
  },
  {
    src: "38-azotea--20230124040152.jpg",
  },
  {
    src: "23-terraza--20230124040104.jpg",
  },
  {
    src: "15-terraza--20230124040137.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/21n7sXq4VdU";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carretera%20de%20murcia%2017%2018011%20granada",
    text: "Carretera de Murcia 17  18011 Granada",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=san%20juan%20de%20dios%201%2018001%20granada",
    text: "San Juan de Dios 1, 18001 Granada",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:958804625",
    text: "958804625",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@gralusa.com",
    text: "info@gralusa.com",
  },
];
